import { AppProvider } from 'components';
import { SnackbarProvider } from 'notistack';

import { Box, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import { QueryClientWrapper } from 'components/query-client-wrapper';
import { theme, themeColors } from 'theme';

import { Routes } from './routes/Routes';
console.log('production');
function App() {
  return (
    <AppProvider>
      <QueryClientWrapper>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <Box
                display="flex"
                sx={{ backgroundColor: themeColors.grey20 }}
                flexDirection="column"
                minHeight="100vh"
              >
                <SnackbarProvider
                  maxSnack={8}
                  autoHideDuration={1000000}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Routes />
                </SnackbarProvider>
              </Box>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientWrapper>
    </AppProvider>
  );
}

export default App;
